import { PluralTranslatePipe, TranslationsLoader } from '@agilox/common';
import { GoogleAnalyticsDirective } from '@agilox/directive/google-analytics';
import { PipeEmptyModule } from '@agilox/pipe/empty';
import { PipeSecondsModule } from '@agilox/pipe/seconds';
import { PipeTrustedModule } from '@agilox/pipe/trusted';
import {
	BoxComponent,
	BoxContentComponent,
	BreadcrumbsComponent,
	ButtonComponent,
	CheckboxComponent,
	DividerComponent,
	EmptyStateComponent,
	ErrorContentComponent,
	HeadlineComponent,
	IconModule,
	InputModule,
	NavListModule,
	NavModule,
	NotifyComponent,
	OverlayComponent,
	SelectModule,
	SpinnerComponent,
	TagComponent,
	ToggleComponent,
	TooltipModule,
} from '@agilox/ui';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { LessonComponent } from './components/lesson/lesson.component';
import { MediaListComponent } from './components/media-list/media-list.component';
import { VideoComponent } from './components/video/video.component';
import { CertificateTagTypePipe } from './pipes/certificate-tag-type-pipe';
import { CertificateTagTextPipe } from './pipes/certificate-tag-text-pipe';
import { SecondsToMinutesPipe } from './pipes/minute-pipe';

/**
 * create all common-used components, pipes, services and modules here
 */
@NgModule({
	declarations: [
		LessonComponent,
		MediaListComponent,
		VideoComponent,
		SecondsToMinutesPipe,
		CertificateTagTypePipe,
		CertificateTagTextPipe,
	],
	imports: [
		CommonModule,
		TranslateModule.forChild({
			loader: {
				provide: TranslateLoader,
				useClass: TranslationsLoader,
				deps: [HttpClient],
			},
		}),
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		HeadlineComponent,
		ButtonComponent,
		IconModule,
		InputModule,
		EmptyStateComponent,
		CheckboxComponent,
		TooltipModule,
		NotifyComponent,
		NavModule,
		NavListModule,
		PipeEmptyModule,
		PipeSecondsModule,
		PipeTrustedModule,
		ToggleComponent,
		TagComponent,
		SpinnerComponent,
		BoxContentComponent,
		SelectModule,
		GoogleAnalyticsDirective,
		BoxComponent,
		PluralTranslatePipe,
		DividerComponent,
		BreadcrumbsComponent,
		OverlayComponent,
		ErrorContentComponent,
	],
	exports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		TranslateModule,
		HttpClientModule,
		HeadlineComponent,
		ButtonComponent,
		IconModule,
		InputModule,
		EmptyStateComponent,
		CheckboxComponent,
		TooltipModule,
		NotifyComponent,
		NavModule,
		NavListModule,
		PipeEmptyModule,
		PipeSecondsModule,
		PipeTrustedModule,
		ToggleComponent,
		TagComponent,
		SpinnerComponent,
		BoxContentComponent,
		SelectModule,
		GoogleAnalyticsDirective,
		BoxComponent,
		CertificateTagTypePipe,
		CertificateTagTextPipe,
		SecondsToMinutesPipe,
		PluralTranslatePipe,
		MediaListComponent,
		LessonComponent,
		BreadcrumbsComponent,
		OverlayComponent,
		ErrorContentComponent,
	],
})
export class SharedModule {}
