<div class="ui-rich-text-editor" (click)="setFocus()">
	@if (label) {
		<label class="ui-rich-text-editor__label">{{ label }}</label>
	}
	<ui-rich-text-editor-toolbar
		(redo)="onRedo()"
		(undo)="onUndo()"
		[id]="'rich-text-editor-toolbar-' + name()"
	></ui-rich-text-editor-toolbar>

	<quill-editor
		(onEditorCreated)="editorCreated($event)"
		[modules]="quillConfiguration"
		class="ui-rich-text-editor__editor"
		(onContentChanged)="contentChanged($event)"
		[placeholder]="placeholder"
		(onFocus)="setFocus()"
		(focusout)="focusOut()"
		[ngClass]="{ 'is-focused': focused }"
	>
	</quill-editor>
</div>
