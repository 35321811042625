import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	inject,
	Input,
	Output,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { SelectOption } from '@agilox/ui-common';
import { SelectCustomOptionDirective } from '../../directives';
import { SelectOptionGroup } from '../../interfaces';
import { CompareFnType } from '../../types/compareFn.type';

@Component({
	selector: 'ui-select-option-list',
	templateUrl: './select-option-list.component.html',
	styleUrls: ['./select-option-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class SelectOptionListComponent {
	@Input() set options(options: Array<SelectOption<any>>) {
		this.groupOptions(options);
	}

	optionGroups: Array<SelectOptionGroup<any>> = [];

	@Input() multiEnabled: boolean = false;

	@Input() fieldToCompareBy: string = '';

	private _selectedValues: Array<SelectOption<any>> | SelectOption<any> = [];

	get selectedValues(): Array<SelectOption<any>> | SelectOption<any> {
		return this._selectedValues;
	}

	@Input() set selectedValues(value: Array<SelectOption<any>> | SelectOption<any>) {
		this._selectedValues = value;
		this.disableGroup();
		if (!Array.isArray(value)) {
			// wait for the next tick to scroll the option into view
			Promise.resolve().then(() => {
				this.scrollOptionIntoView(value);
			});
		}
	}

	@Output() selected: EventEmitter<any> = new EventEmitter<any>();

	@ViewChild('optionsList') optionsList: ElementRef<HTMLUListElement> | undefined;

	@Input() customOptionTemplate: SelectCustomOptionDirective | undefined;

	@Input() groupSelectable: boolean = false;

	@Input() customCompareFn: CompareFnType | undefined;

	@Input() fullWidth: boolean = false;

	@Output() scrollEnd: EventEmitter<void> = new EventEmitter<void>();

	/**
	 * Needed for keyboard navigation
	 */
	focusedOption: SelectOption<any> | undefined;
	private _totalSelectableOptions: number = 0;

	get totalSelectableOptions(): number {
		return this._totalSelectableOptions;
	}

	private cd: ChangeDetectorRef = inject(ChangeDetectorRef);

	toggleOption(option: SelectOption<any>) {
		if (option.isDisabled) {
			return;
		}
		this.selected.emit(option.value);
		this.disableGroup();
	}

	/**
	 * Groups the options by group name
	 * @param options
	 */
	groupOptions(options: Array<SelectOption<any>>): void {
		const groupedItemsMap: Map<string, Array<SelectOption<any>>> = new Map<
			string,
			Array<SelectOption<any>>
		>();
		options.forEach((option: SelectOption<any>) => {
			const group: string = option.group || '';
			if (!groupedItemsMap.has(group)) {
				groupedItemsMap.set(group, []);
			}
			groupedItemsMap.get(group)!.push(option);
		});
		// if all options are selected, then the group is also selected!
		this.optionGroups = Array.from(groupedItemsMap.entries()).map(([name, options]) => ({
			name,
			options,
		}));
		this.disableGroup();
		this.setOptionIndexes();
	}

	/**
	 * if there are any selected then we deselect all in the group
	 * if there are none selected then we select all in the group
	 */
	onGroupToggle(group: SelectOptionGroup<any>) {
		if (!this.groupSelectable || group.isDisabled) return;

		const optionsToToggle: Array<any> = [];

		const hasSelected: boolean =
			group.options.map((option: SelectOption<any>) => this.compareFn(option)).filter((s) => s)
				.length > 0;
		group.options.forEach((option: SelectOption<any>) => {
			if (!option.isDisabled) {
				if (hasSelected) {
					/** Only emit the options that need to be set to false **/
					if (this.compareFn(option)) {
						optionsToToggle.push(option.value);
					}
				} else {
					/** Only emit the options that need to be set to true **/
					if (!this.compareFn(option)) {
						optionsToToggle.push(option.value);
					}
				}
			}
		});
		this.selected.emit(optionsToToggle);
	}

	/**
	 * Set the index of each option to be used for keyboard navigation
	 */
	setOptionIndexes(): void {
		let index: number = 0;
		/**
		 * Set the index of each option to be used for keyboard navigation
		 */
		this.optionGroups.forEach((group: SelectOptionGroup<any>) => {
			group.options.forEach((option: SelectOption<any>) => {
				if (!option.isDisabled) {
					option.index = index;
					index++;
				}
			});
		});
		this._totalSelectableOptions = index;
	}

	/**
	 * Listen for arrow up and arrow down keydown events
	 * and set the focus to the next or previous element
	 */

	@HostListener('document:keydown.arrowdown', ['$event'])
	onArrowDown($event: KeyboardEvent) {
		/**
		 * Prevent page from scrolling
		 */
		$event.stopPropagation();
		$event.preventDefault();

		/**
		 * If there is no focused option, set it to the first option,
		 * that is not disabled
		 */
		if (!this.focusedOption || this.focusedOption.index === undefined) {
			this.focusedOption = this.optionGroups[0].options.find((option) => !option.isDisabled);
			return;
		}

		let nextIndex: number = this.focusedOption.index + 1;
		if (nextIndex >= this._totalSelectableOptions) {
			nextIndex = 0;
		}
		this.setFocusedOptionByIndex(nextIndex);
	}

	@HostListener('document:keydown.arrowup', ['$event'])
	onArrowUp($event: KeyboardEvent) {
		/**
		 * Prevent the page from scrolling
		 */
		$event.stopPropagation();
		$event.preventDefault();

		/**
		 * If there is no focused option, set it to the first option
		 */
		if (!this.focusedOption || this.focusedOption.index === undefined) {
			this.focusedOption = this.optionGroups[0].options[0];
			return;
		}

		let previousIndex: number = this.focusedOption.index - 1;
		if (previousIndex < 0) {
			previousIndex = this.totalSelectableOptions - 1;
		}
		this.setFocusedOptionByIndex(previousIndex);
	}

	private setFocusedOptionByIndex(index: number): void {
		let foundOption: SelectOption<any> | undefined;
		this.optionGroups.forEach((group) => {
			group.options.forEach((option) => {
				if (option.index === index) {
					foundOption = option;
				}
			});
		});
		this.focusedOption = foundOption || this.focusedOption;
		this.scrollOptionIntoView(this.focusedOption!);
	}

	/**
	 * Scroll the option into view when navigating with the keyboard
	 * @param option
	 */
	scrollOptionIntoView(option: SelectOption<any>): void {
		if (!this.optionsList) {
			return;
		}

		const optionElement: HTMLElement | null = this.optionsList.nativeElement.querySelector(
			`[data-option-index="${option.index}"]`
		);
		if (!optionElement) {
			return;
		}
		optionElement.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
	}

	/**
	 * Listen for enter keydown event
	 * and emit the selected option
	 */
	@HostListener('document:keydown.enter')
	onKeyboardEnter() {
		if (this.focusedOption) {
			this.selected.emit(this.focusedOption.value);
		}
	}

	compareFn(option: SelectOption<any>): boolean {
		if (this.customCompareFn) {
			return this.customCompareFn(option, this.selectedValues);
		} else {
			if (Array.isArray(this._selectedValues)) {
				return this._selectedValues.some((value) => {
					if (this.fieldToCompareBy) {
						return value.value[this.fieldToCompareBy] === option.value[this.fieldToCompareBy];
					} else {
						return value.title === option.title;
					}
				});
			}

			if (this.fieldToCompareBy) {
				return (
					this._selectedValues.value[this.fieldToCompareBy] === option.value[this.fieldToCompareBy]
				);
			} else {
				return this._selectedValues.title === option.title;
			}
		}
	}

	disableGroup() {
		this.optionGroups.forEach((group: SelectOptionGroup<any>) => {
			group.isDisabled =
				group.options.filter((option) => option.isDisabled).length === group.options.length;
		});
		this.cd.markForCheck();
	}

	onScroll() {
		if (this.optionsList) {
			const element = this.optionsList.nativeElement;
			const atBottom = element.scrollHeight - element.scrollTop === element.clientHeight;
			if (atBottom) {
				this.scrollEnd.emit();
			}
		}
	}
}
