import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	forwardRef,
	inject,
	Input,
	Output,
	ViewChild,
} from '@angular/core';
import {
	AbstractControl,
	ControlValueAccessor,
	FormControl,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ReactiveFormsModule,
	ValidationErrors,
} from '@angular/forms';
import { Position, Positions } from '@agilox/ui-common';
import { takeUntil } from 'rxjs';
import { NgClass, NgIf } from '@angular/common';
import { IconModule } from '../icon/icon.module';
import { TooltipModule } from '../tooltip/tooltip.module';
import { Unsubscribe } from '@agilox/common';

@Component({
	selector: 'ui-textarea',
	standalone: true,
	imports: [ReactiveFormsModule, NgClass, NgIf, IconModule, TooltipModule],
	templateUrl: './textarea.component.html',
	styleUrl: './textarea.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => TextareaComponent),
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => TextareaComponent),
			multi: true,
		},
	],
})
export class TextareaComponent extends Unsubscribe implements ControlValueAccessor {
	@Input() errorText: string = '';
	@Input() label: string = '';

	/**
	 * Will display the o_info icon if this property is set.
	 * Hovering on the icon will display the tooltip.
	 */
	@Input() labelTooltip: string = '';
	@Input() labelTooltipPosition: Position = Positions.Top;

	@Input() textUnderInput: string = '';
	@Input() placeholder: string = '';

	@Input() showWhenValid: boolean = false;

	/**
	 * Will output anytime the input changes.
	 * It is also possible to subscribe to the value changes on the formControl
	 */
	@Output() valueChanged: EventEmitter<any> = new EventEmitter<any>();
	public formControl: FormControl = new FormControl();

	focused: boolean = false;

	@ViewChild('textareaElement') textarea: ElementRef | undefined;

	/**
	 * Should only be used when the parent is using ngModel and not
	 * formControl.
	 * It should be handled via the formControl if applicable!
	 * @param dis
	 */
	@Input() set disabled(dis: boolean) {
		this.setDisabledState(dis);
	}

	@Input() resizeDisabled: boolean = true;

	private cdRef: ChangeDetectorRef = inject(ChangeDetectorRef);
	constructor() {
		super();
	}

	registerOnChange(fn: any): void {
		this.formControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
			fn(value);
			this.valueChanged.emit(value);
		});
	}

	registerOnTouched(fn: any): void {
		this.formControl.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(fn);
	}

	writeValue(value: any): void {
		if (this.formControl && this.formControl.value !== value) {
			this.formControl.setValue(value);
			this.cdRef.markForCheck();
		}
	}

	/**
	 * Do not delete, is called automatically by the form
	 */
	validate(control: AbstractControl): ValidationErrors | null {
		if (!this.formControl.validator) {
			this.formControl.setValidators(control.validator);
		}

		return null;
	}

	onFocusOut() {
		this.focused = false;
		/** Force the sanitization of the value */
		this.writeValue(this.formControl.value);
	}

	setDisabledState(isDisabled: boolean) {
		isDisabled ? this.formControl.disable() : this.formControl.enable();
		this.cdRef.markForCheck();
	}
}
