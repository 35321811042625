<div class="ui-textarea">
	@if (label) {
		<div class="ui-textarea__label">
			<label [for]="'textarea-' + label">{{ label }}</label>
			@if (labelTooltip) {
				<ui-icon
					[uiTooltip]="labelTooltip"
					[tooltipPosition]="labelTooltipPosition"
					icon="o_info"
					size="xs"
					class="ui-textarea__infoIcon"
				>
				</ui-icon>
			}
		</div>
	}
	<textarea
		[id]="'textarea-' + label"
		[formControl]="formControl"
		class="ui-textarea__textarea"
		[placeholder]="placeholder"
		(focusout)="onFocusOut()"
		(focusin)="focused = true"
		[ngClass]="{
			'ui-textarea__textarea--valid':
				!focused && formControl.valid && showWhenValid && formControl.value,
			'ui-textarea--error': !focused && formControl.invalid && formControl.value,
			'ui-textarea--resize': !resizeDisabled,
		}"
	></textarea>

	<div
		class="ui-textarea__bottomText"
		[ngClass]="{
			'ui-textarea__bottomText--valid':
				!focused && formControl.valid && showWhenValid && formControl.value,
		}"
	>
		@if (textUnderInput && formControl.valid) {
			{{ textUnderInput }}
		} @else if (formControl.invalid && errorText) {
			{{ errorText }}
		}
	</div>
</div>
