import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	inject,
	Input,
	OnInit,
	ViewChild,
} from '@angular/core';
import { App, GoogleAnalyticsService } from '@agilox/common';
import { UserNotificationsService } from './user-notifications.service';
import { map, Observable, tap, timer, concatMap } from 'rxjs';
import { UserNotificationsState } from './models/userNotificationsState';
import { UserNotification } from './models/user-notification';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'ui-user-notifications',
	templateUrl: './user-notifications.component.html',
	styleUrl: './user-notifications.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserNotificationsComponent implements OnInit {
	private service: UserNotificationsService = inject(UserNotificationsService);
	private googleAnalyticsService: GoogleAnalyticsService = inject(GoogleAnalyticsService);

	/**
	 * How often should the notifications be fetched?
	 * (seconds)
	 */
	fetchInterval: number = 30;

	@Input() app: App = App.analytics;

	notificationsTimer$: Observable<UserNotification[]> = timer(0, this.fetchInterval * 1000).pipe(
		takeUntilDestroyed(),
		concatMap(() => {
			return this.service.fetchNews(this.app);
		})
	);

	notifications$: Observable<UserNotification[]> = this.service.state$.pipe(
		map((state: UserNotificationsState) => state.notifications)
	);

	offcanvasOpen$: Observable<boolean> = this.service.state$.pipe(
		map((state: UserNotificationsState) => state.isOpen),
		tap((open: boolean) => {
			setTimeout(() => {
				if (this.articlesDiv) {
					this.articlesDiv.nativeElement.scrollTop = 0;
				}
				if (open) {
					this.googleAnalyticsService.eventEmitter('click: open notifications', this.app);
				}
			});
		})
	);

	@ViewChild('articles') articlesDiv: ElementRef | undefined;

	ngOnInit() {
		this.notificationsTimer$.subscribe((notifications: UserNotification[]) => {
			this.service.setNews(notifications);
		});
	}

	closeOffcanvas() {
		this.service.toggleNotificationsOffcanvas();
		this.service.markAsRead().subscribe((resp: { status: boolean }) => {
			if (resp.status) {
				this.service.setStateToRead();
			}
		});
	}
}
