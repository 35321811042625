import { Pipe, PipeTransform } from '@angular/core';
import { dateStringToISO } from '@agilox/common';

@Pipe({
	name: 'dateStringToISO',
	standalone: true,
})
export class DateStringToISOPipe implements PipeTransform {
	transform(value: string | null): string | null {
		if (!value) {
			return null;
		}
		return dateStringToISO(value);
	}
}
