import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ContentChild,
	EventEmitter,
	forwardRef,
	Input,
	OnInit,
	Output,
	signal,
	ViewChild,
	ViewEncapsulation,
	WritableSignal,
} from '@angular/core';
import {
	AbstractControl,
	ControlValueAccessor,
	FormControl,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ValidationErrors,
} from '@angular/forms';
import { SelectOption } from '@agilox/ui-common';
import { SelectCustomOptionDirective } from './directives';
import { DropdownDirective } from '../dropdown/directives';
import { CompareFnType } from './types/compareFn.type';
import { SearchFn } from './types/searchFn.type';

/**
 * Select Component:
 *
 * Usage:
 * There are two ways to pass the options to the select component:
 * 1. Pass the options as an array of SelectOption objects to the options input
 * 2. You can implement a custom template for the options, by using the SelectCustomOptionDirective
 * in the parent component, and passing the options as an array of objects to the options input.
 */

@Component({
	selector: 'ui-select',
	templateUrl: './select.component.html',
	styleUrls: ['./select.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SelectComponent),
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => SelectComponent),
			multi: true,
		},
	],
})
export class SelectComponent implements ControlValueAccessor, OnInit {
	@Input() showWhenValid: boolean = false;
	@Input() placeholder: string = 'select.placeholder';
	@Input() multiple: boolean = false;
	@Input() fieldToCompare: string = '';
	@Input() inputId: string = '';

	/**
	 * If true, the clear button will be shown
	 * when the input is not empty and it's not multiple
	 * If multiple is true, the clear button will not be shown
	 */
	@Input() clearEnabled: boolean = false;

	@Input() groupSelectable: boolean = false;

	@Input() searchEnabled: boolean = false;

	/**
	 * If true, the dropdown will not have a fixed width, but will be as wide as the longest option
	 */
	@Input() fullDropdownWidth: boolean = false;

	/**
	 * Can be used to disable the select all button
	 * the button is however only shown when the multiple input is true
	 */
	@Input() selectAllEnabled: boolean = true;

	@Input() set options(options: Array<SelectOption<any>>) {
		this._options = options;
		this.filteredOptions.set(options);
		if (this.cachedValue) {
			this.writeValue(this.cachedValue);
			this.cachedValue = null;
		}
	}

	private _options: Array<SelectOption<any>> = [];

	get options() {
		return this._options ?? [];
	}

	/** The standard compareFn can be overwritten by passing a function **/
	@Input() compareFn: CompareFnType | undefined;

	/**
	 * This is sadly needed because we have no way of knowing if
	 * the formControl has been marked as touched or not by the parent.
	 * See: https://github.com/angular/angular/issues/45089
	 * @param value
	 */
	@Input() set touched(value: boolean) {
		if (value) {
			this.formControl.markAsTouched();
			return;
		}
		this.formControl.markAsUntouched();
	}

	/** The search function can be overwritten by passing a function **/
	@Input() searchFn: SearchFn | undefined;

	@Input() maxSelections: number | undefined = undefined;
	@Input() maxSelectionsText: string = 'select.maxSelectionsText';

	@Output() search: EventEmitter<string> = new EventEmitter();

	@Output() selected: EventEmitter<any> = new EventEmitter<any>();

	@Output() opened: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Output() saved: EventEmitter<any> = new EventEmitter<any>();

	@Output() scrollEnd: EventEmitter<void> = new EventEmitter<void>();

	@ContentChild(SelectCustomOptionDirective) customOptionTemplate:
		| SelectCustomOptionDirective
		| undefined;

	dropdownOpen: boolean = false;

	public formControl: FormControl<any> = new FormControl();

	/** The options are stored in this formControl, not just the values **/
	optionsFormControl: FormControl<any> = new FormControl();

	/**
	 * This is used as a temporary variable to store the values that were selected
	 * when saveRequired is true. The values here will be set to the formControl.value
	 * when the save button is clicked.
	 */
	savedValues: Array<SelectOption<any>> = [];

	@ViewChild(DropdownDirective) dropdown: DropdownDirective | undefined;

	filteredOptions: WritableSignal<Array<SelectOption<any>>> = signal([]);

	/**
	 * Should only be used when the parent is using ngModel and not
	 * formControl.
	 * It should be handled via the formControl if applicable!
	 * @param disabled
	 */
	@Input() set disabled(disabled: boolean) {
		this.setDisabledState(disabled);
	}

	/**
	 * Needed in case the value is set before the options are set
	 * @private
	 */
	private cachedValue: any;

	constructor(private cd: ChangeDetectorRef) {}

	ngOnInit() {
		this.filteredOptions.set(this.options);
	}

	private onChange: (value: any) => void = () => {};
	private onTouched: () => void = () => {};

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	// prettier-ignore
	filterOptions = (searchTerm: string): SelectOption<any>[] => {

		if(this.searchFn) {
			return this.searchFn(searchTerm, this.options)
		}

		const searchTermLC = searchTerm.toLowerCase();
		return searchTerm
			? this.options.filter(option => option.title.toLowerCase().includes(searchTermLC))
			: this.options;
	};

	/**
	 * Handles the selection of a value
	 * Array will be passed when there are preselected values, and will be automatically
	 * called when the component is initialized
	 * Option will be null if reset() is called on the form / formControl or it is manually set to null
	 * @param option
	 */
	writeValue(option: any): void {
		if (option === null) {
			this.resetControls();
		} else {
			if (!this.options.length) {
				if (this.multiple && Array.isArray(option)) {
					this.cachedValue = option;
				} else if (!this.multiple) {
					this.cachedValue = option;
				}
				return;
			}
			if (this.multiple) {
				this.writeMultiValue(option);
			} else {
				this.writeSingleValue(option);
			}
		}
	}

	resetControls() {
		this.formControl.setValue(null, { emitEvent: false });
		this.optionsFormControl.setValue(null, { emitEvent: false });
		this.formControl.markAsPristine();
		this.optionsFormControl.markAsPristine();
		this.cachedValue = null;
		this.savedValues = [];
		this.cd.markForCheck();
	}

	/**
	 * Handles the selection of a single value, when multiple is false
	 * @param option
	 */
	writeSingleValue(option: any): void {
		this.closeDropdown();

		const selectOption: SelectOption<any> | undefined = this.findOption(option);
		if (selectOption) {
			this.setValue(selectOption);
		}
	}

	/**
	 * Handles the selection of multiple values, when multiple is true
	 * An array can be passed to this function, in case the values are already selected
	 * @param option
	 */
	writeMultiValue(option: any): void {
		if (!this.optionsFormControl.value) {
			this.optionsFormControl.setValue([]);
		}
		if (!this.formControl.value) {
			this.formControl.setValue([]);
		}
		/**
		 * if the option passed to the function is an array, then it means that the values are already selected (preselected in parent component),
		 * and we need to set the formControl to the values in order for the cloneFormValue function to work
		 *
		 * In some cases (when the ngModel or formControl is being manipulated from the parent component),
		 * the option will also be an array and must be handled as such
		 */
		if (Array.isArray(option)) {
			/**
			 * Find the matching selectOptions
			 */
			const selectOptions: Array<SelectOption<any>> = [];
			option.forEach((opt: any) => {
				// if value is in the optionsFormControl.value array dont push it to selectOptions
				const foundOption = this.findOption(opt);
				if (foundOption) {
					selectOptions.push(foundOption);
				}
			});
			if (selectOptions.length === 0) {
				this.cachedValue = option;
				return;
			}

			if (this.optionsFormControl.value !== selectOptions) {
				this.setValue(selectOptions);
			}
			return;
		} else {
			/**
			 * Find the matching selectOption
			 */
			const selectOption: SelectOption<any> | undefined = this.findOption(option);

			/**
			 * save the values in a temporary variable, so that they can be set to the formControl when the save button is clicked
			 * if saveRequired is false, then the values will be set to the formControl immediately
			 */
			const values: Array<SelectOption<any>> = this.multiple
				? this.savedValues
				: this.optionsFormControl.value;

			if (selectOption) {
				const optionIndex = values.findIndex(
					(value: SelectOption<any>) => value.title === selectOption.title
				);

				if (optionIndex !== -1) {
					values.splice(optionIndex, 1);
				} else {
					values.push(selectOption);
				}
			}

			if (!this.multiple) {
				this.setValue(values);
			} else {
				this.savedValues = [...values];
			}
		}
	}

	deselectAll() {
		/**
		 * Only deselects the options that are currently shown and not disabled.
		 */
		const set: Set<string> = new Set(
			this.filteredOptions()
				.filter((o) => !o.isDisabled)
				.map((option) => option.title)
		);
		this.savedValues = this.savedValues.filter((option) => !set.has(option.title));
		this.selected.emit(this.savedValues);
	}

	/**
	 * Only selects the options that are currently shown
	 */
	selectAll() {
		this.savedValues = this.filteredOptions().filter((option) => !option.isDisabled);
		this.selected.emit(this.savedValues);
	}

	private findOption(option: any): SelectOption<any> | undefined {
		return this.options.find((opt) => {
			if (!this.fieldToCompare) {
				return JSON.stringify(opt.value) === JSON.stringify(option);
			} else {
				if (option) {
					return opt.value[this.fieldToCompare] === option[this.fieldToCompare];
				} else {
					return false;
				}
			}
		});
	}

	private setValue(value: SelectOption<any> | Array<SelectOption<any>>) {
		// need just the values and not the entire SelectOption
		const parsed: any = Array.isArray(value) ? value.map((val) => val.value) : value.value;
		this.optionsFormControl.setValue(value, { emitEvent: false });
		this.formControl.setValue(parsed, { emitEvent: false });
		this.cd.markForCheck();
		this.optionsFormControl.markAsDirty();
		this.onChange(parsed);
		this.formControl.markAsDirty();
		this.cloneFormValue();
	}

	optionSelected(option: any) {
		this.onTouched();
		this.selected.emit(option);
	}

	onSave() {
		this.setValue(this.savedValues || []);
		this.toggleDropdown();
		this.saved.emit();
	}

	openDropdown() {
		this.dropdown?.openDropdown();
	}

	toggleDropdown() {
		this.dropdown?.toggleDropdown();
	}

	closeDropdown() {
		this.dropdown?.closeDropdown();
	}

	onDropdownStateChange(open: boolean) {
		this.dropdownOpen = open;
		if (open) {
			this.cloneFormValue();
			this.filteredOptions.set(this._options);
			this.opened.emit(true);
		}
		this.cd.markForCheck();
	}

	cloneFormValue() {
		if (this.optionsFormControl.value && this.multiple) {
			this.savedValues = JSON.parse(JSON.stringify(this.optionsFormControl.value));
		}
	}

	onSearch(val: string) {
		this.search.emit(val);
		this.filteredOptions.set(this.filterOptions(val));
	}

	clearSingleSelection(): void {
		this.writeValue(null);
	}

	setDisabledState(isDisabled: boolean) {
		isDisabled ? this.formControl.disable() : this.formControl.enable();
		this.cd.markForCheck();
	}

	validate(control: AbstractControl): ValidationErrors | null {
		if (!this.formControl.validator) {
			this.formControl.setValidators(control.validator);
		}

		return null;
	}

	onScrollEnd() {
		this.scrollEnd.emit();
	}
}
