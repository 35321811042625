import {
	ChangeDetectionStrategy,
	Component,
	forwardRef,
	input,
	Input,
	InputSignal,
	OnInit,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import Quill from 'quill';
import { ContentChange } from 'ngx-quill';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Unsubscribe } from '@agilox/common';
import { RichTextEditorToolbarComponent } from './rich-text-editor-toolbar/rich-text-editor-toolbar.component';

@Component({
	selector: 'ui-rich-text-editor',
	templateUrl: './rich-text-editor.component.html',
	styleUrl: './rich-text-editor.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => RichTextEditorComponent),
			multi: true,
		},
	],
})
export class RichTextEditorComponent extends Unsubscribe implements ControlValueAccessor, OnInit {
	name: InputSignal<string> = input.required<string>();
	@Input() label: string = '';
	@Input() placeholder: string = '';

	@ViewChild(RichTextEditorToolbarComponent, { static: true }) toolbar:
		| RichTextEditorToolbarComponent
		| undefined;

	quillEditor: Quill | undefined;

	quillConfiguration: any = {};

	focused: boolean = false;

	ngOnInit() {
		if (this.name()) {
			this.quillConfiguration = {
				...this.quillConfiguration,
				toolbar: '#rich-text-editor-toolbar-' + this.name(),
			};
		}
	}

	editorCreated(editor: Quill) {
		this.quillEditor = editor;
		this.setHtml();
	}

	onChange: any = () => {};
	onTouched: any = () => {};

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		if (this.quillEditor) {
			this.quillEditor.enable(!isDisabled);
		}
	}

	writeValue(html: string): void {
		this.editorValue = html ?? '';
		if (this.quillEditor) {
			this.setHtml();
		}
	}

	setHtml() {
		if (this.quillEditor) {
			const delta = this.quillEditor.clipboard.convert({ html: this.editorValue });
			if (delta) {
				this.quillEditor.setContents(delta);
			}
		}
	}

	editorValue: string = '';

	onUndo() {
		if (this.quillEditor) {
			this.quillEditor.history.undo();
		}
	}

	onRedo() {
		if (this.quillEditor) {
			this.quillEditor.history.redo();
		}
	}

	setFocus() {
		this.focused = true;
	}
	focusOut() {
		this.focused = false;
	}

	contentChanged(event: ContentChange) {
		this.onChange(event.html);
		this.onTouched();
	}
}
