<ui-offcanvas
	[header]="'notifications.title' | translate"
	[open]="!!(offcanvasOpen$ | async)"
	(closed)="closeOffcanvas()"
>
	@if (notifications$ | async; as notifications) {
		<div class="c-user-notifications" data-cy="user-notifications-offcanvas">
			<div class="c-user-notifications__articles" #articles>
				@for (notification of notifications; track notification; let idx = $index) {
					<ui-user-notification-item [notification]="notification"></ui-user-notification-item>
					@if (idx < notifications.length - 1) {
						<ui-divider class="c-user-notifications__divider"></ui-divider>
					}
				}
			</div>
		</div>
	} @else {
		<ui-spinner></ui-spinner>
	}
	<div offcanvas-buttons>
		<ui-button
			[text]="'general.close' | translate"
			[fullWidth]="true"
			(buttonClicked)="closeOffcanvas()"
		></ui-button>
	</div>
</ui-offcanvas>
