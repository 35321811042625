/**
 * Transforms a date string in the format 'dd.mm.yyyy' to an ISO string
 * of the format 'yyyy-mm-dd'
 * @param {string} value - The date string to transform
 * @returns {string} - The transformed ISO date string
 */
export function dateStringToISO(value: string): string {
	const day: number = parseInt(value.slice(0, 2), 10);
	const month: number = parseInt(value.slice(3, 5), 10);
	const year: number = parseInt(value.slice(6, 10), 10);

	// Pad day and month to two characters
	const paddedDay = day.toString().padStart(2, '0');
	const paddedMonth = month.toString().padStart(2, '0');

	return `${year}-${paddedMonth}-${paddedDay}`;
}

/**
 * Transforms a date string in the format 'dd.mm.yyyy' to a Date object
 * @param {string} value - The date string to transform
 * @returns {Date} - The Date object
 */
export function stringToDate(value: string): Date {
	const day: number = parseInt(value.slice(0, 2), 10);
	const month: number = parseInt(value.slice(3, 5), 10);
	const year: number = parseInt(value.slice(6, 10), 10);
	return new Date(year, month - 1, day);
}

/**
 * Transforms an ISO date string to a date string in the format 'dd.mm.yyyy'
 * @param {string} date - The ISO date string to transform
 * @returns {string} - The transformed date string
 */
export function ISOStringToDateString(date: string): string {
	if (!date) return '';

	const day: string = date.slice(8, 10);
	const month: string = date.slice(5, 7);
	const year: string = date.slice(0, 4);
	return `${day}.${month}.${year}`;
}

export function isStringValidDate(value: string): boolean {
	if (value.length < 10) {
		return false;
	}

	const date: Date = new Date(stringToDate(value));
	return !isNaN(date.getTime());
}

export function timestampStringToDate(timestamp: string): Date {
	return new Date(parseInt(timestamp, 10) * 1000);
}

export function convertToUTCDate(date: string | number | Date): Date {
	const dateObj = new Date(date);
	return new Date(dateObj.getTime() + dateObj.getTimezoneOffset() * 60000);
}

export function addTimezoneOffset(timestamp: number) {
	return timestamp + new Date(timestamp).getTimezoneOffset() * 60000;
}

export function dateToTimestamp(dateValue: Date | string, timeValue: string): number {
	if (!dateValue) {
		return 0;
	}
	let date: Date;

	/**
	 * If the date gets passed via a string
	 * e.g. from the datepicker, we need to convert it to a date object
	 * and fix the timezone offset which might be off by a day
	 */
	if (typeof dateValue === 'string') {
		date = new Date(dateValue);
		dateValue = convertToUTCDate(dateValue);
	} else {
		date = dateValue;
	}

	const time = timeValue || '00:00';

	const [hours, minutes] = time.split(':').map(Number);
	date.setHours(hours, minutes, 0, 0); // Set hours, minutes, seconds, milliseconds
	date.setFullYear(dateValue.getFullYear(), dateValue.getMonth(), dateValue.getDate());

	return new Date(date).getTime() - new Date(date).getTimezoneOffset() * 60000;
}

export function timeToTimestamp(time: string): number {
	if (!time || time === '00:00') {
		return 0;
	}
	const [hours, minutes] = time.split(':').map(Number);

	// Convert hours and minutes to milliseconds
	const hoursInMilliseconds = hours * 60 * 60 * 1000;
	const minutesInMilliseconds = minutes * 60 * 1000;

	// Add up the total time in milliseconds
	return hoursInMilliseconds + minutesInMilliseconds;
}

export function timestampToTime(timestamp: number, defaultValue = '00:00'): string {
	if (!timestamp || timestamp <= 0) {
		return defaultValue;
	}
	const date = new Date(timestamp);
	const hours = String(date.getHours()).padStart(2, '0');
	const minutes = String(date.getMinutes()).padStart(2, '0');
	return `${hours}:${minutes}`;
}

export function timestampToUTCTime(timestamp: number, defaultValue = '00:00'): string {
	const date = new Date(timestamp);
	const hours = String(date.getUTCHours()).padStart(2, '0');
	const minutes = String(date.getMinutes()).padStart(2, '0');
	return `${hours}:${minutes}`;
}

/**
 * Takes a date object and returns a string in the format 'yyyy-mm-dd'
 * It will ignore the time part of the date object and the timezone
 * @param {Date} date - The date object to transform
 */
export function dateToString(date: Date) {
	// Extract the year, month, and day from the date object
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based
	const day = String(date.getDate()).padStart(2, '0');

	// Return the date in the 'yyyy-mm-dd' format
	return `${year}-${month}-${day}`;
}
