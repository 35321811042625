import { Meta, Response, Role, Vehicle } from '@agilox/common';
import { DropdownDirective } from '@agilox/ui';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	forwardRef,
	inject,
	Input,
	ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
	AbstractControl,
	ControlValueAccessor,
	FormControl,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ValidationErrors,
} from '@angular/forms';
import {
	BehaviorSubject,
	combineLatest,
	debounceTime,
	distinctUntilChanged,
	map,
	Observable,
	startWith,
	Subject,
	switchMap,
	tap,
} from 'rxjs';
import { VehicleSelectOptionGroup } from './models/vehicle-select-option-group.interface';
import { VehicleUnionSelectService } from './services/vehicle-union-select.service';

@Component({
	selector: 'ui-vehicle-union-select',
	templateUrl: './vehicle-union-select.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => VehicleUnionSelectComponent),
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => VehicleUnionSelectComponent),
			multi: true,
		},
	],
})
export class VehicleUnionSelectComponent implements ControlValueAccessor {
	public service: VehicleUnionSelectService = inject(VehicleUnionSelectService);
	private cdRef: ChangeDetectorRef = inject(ChangeDetectorRef);

	@Input() selectedSerials: string[] = [];

	@Input() set currentSelectedUnion(currentSelectedUnion: string | undefined) {
		this._currentSelectedUnion.next(currentSelectedUnion);
	}

	@Input() userRole: Role = Role.customer;

	@Input() maxSelections: number = 20;

	@Input() displayPills: boolean = true;
	@Input() displayDeselectAll: boolean = true;
	public formControl: FormControl = new FormControl({ value: '', disabled: true });

	public searchFormControl: FormControl = new FormControl();

	private searchObservable$ = this.searchFormControl.valueChanges.pipe(
		startWith(''),
		takeUntilDestroyed(),
		debounceTime(300),
		distinctUntilChanged()
	);

	private page: Meta = { number: 0, size: 50 };
	private currentPage: number = 1;

	private paginationSubject = new Subject<Meta>();
	private pagination$ = this.paginationSubject.asObservable().pipe(
		startWith({
			number: 0,
			size: 50,
		})
	);

	private _currentSelectedUnion = new BehaviorSubject<string | undefined>('');
	public currentSelectedUnion$ = this._currentSelectedUnion.asObservable();

	private _initialLoad: boolean = true;

	public vehicleResponse$: Observable<Vehicle[]> = combineLatest([
		this.searchObservable$,
		this.pagination$,
		this.currentSelectedUnion$,
	]).pipe(
		tap(() => this.formControl.disable()),
		switchMap(([search, page, union]: [string, Meta, string | undefined]) =>
			this.service
				.fetchVehicles(search, page, union || '', this.selectedSerials)
				.pipe(map((response) => this.sortOptions(response)))
		),
		tap((data) => {
			this.page = data.meta;
			this.formControl.enable();
			if (this._initialLoad) {
				this.setVehiclesToSelectedVehiclesFromSerialStrings(this.selectedSerials, data.data);
				this._initialLoad = false;
			}
		}),
		map((data) => data.data)
	);

	onChanged = (value: any) => {};

	onTouched = () => {};

	public dropdownOpen: boolean = false;

	@ViewChild(DropdownDirective) dropdown: DropdownDirective | undefined;

	/**
	 * We do not want to immediately to write the vehicle to the form control
	 * but it should be triggered by the save button
	 * @private
	 */
	private _selectedVehicles: Vehicle[] = [];

	get selectedVehicles(): Vehicle[] {
		return this._selectedVehicles;
	}

	@ViewChild('optionsList') optionsList: ElementRef<HTMLUListElement> | undefined;

	public writeValue(value: any): void {
		this.formControl.setValue(value, { emitEvent: false });
	}

	public registerOnChange(fn: any): void {
		this.onChanged = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	onScroll() {
		if (this.optionsList) {
			const element = this.optionsList.nativeElement;
			const atBottom = element.scrollHeight - element.scrollTop === element.clientHeight;
			if (atBottom) {
				this.nextPage();
			}
		}
	}

	onSelect(vehicle: Vehicle) {
		/**
		 * If the union is the same as the previous one, then we can add/remove the vehicle
		 */
		if (
			this._currentSelectedUnion.value &&
			this._currentSelectedUnion.value === vehicle.unionUuid
		) {
			/**
			 * If the vehicle is to be removed, we need to remove it from the form control
			 * Check if there are any vehicles from the same union in the form control
			 * If there are not any, then we need to clear the currentSelectedUnion
			 */
			if (
				this._selectedVehicles.find((fcVehicle: Vehicle) => fcVehicle.serial === vehicle.serial)
			) {
				this._selectedVehicles = this._selectedVehicles.filter((v) => v.serial !== vehicle.serial);
				if (this._selectedVehicles.length === 0) {
					this._currentSelectedUnion.next(undefined);
				}
				this.cdRef.markForCheck();
			} else {
				this._selectedVehicles = [...this._selectedVehicles, vehicle];
			}
		} else if (this._currentSelectedUnion.value !== vehicle.unionUuid) {
			/**
			 * If the union is different, we need to clear the previous selection and add the new one
			 * This should not be possible because we are disabling the checkboxes from different unions
			 * but this is a safety check
			 */
			this._currentSelectedUnion.next(vehicle.unionUuid);
			this._selectedVehicles = [vehicle];

			/**
			 * We then need to trigger a new backend call to get all the vehicles from the new union
			 */

			this.scrollToTop();
		}

		this.cdRef.markForCheck();
	}

	private nextPage() {
		this.currentPage++;
		this.page.size = 50 * this.currentPage;
		if (this.page.size < (this.page?.total || 0)) {
			this.paginationSubject.next(this.page);
		}
	}

	public isVehicleSelected(vehicle: Vehicle | undefined): boolean {
		return !!this.selectedVehicles.find((v) => v.serial === vehicle?.serial);
	}

	public onDeselectAll() {
		this._selectedVehicles = [];
		this._currentSelectedUnion.next(undefined);
		this.cdRef.detectChanges();
	}

	public onSelectAllInUnion(optionGroup: VehicleSelectOptionGroup) {
		this._selectedVehicles = optionGroup.options
			.filter((option) => !!option.value)
			.map((option) => option.value as Vehicle);

		this.cdRef.markForCheck();
	}

	public onSave() {
		if (this._selectedVehicles.length > this.maxSelections) {
			return;
		}

		this.formControl.setValue(this._selectedVehicles);
		this.onChanged(this._selectedVehicles);
		this.dropdown?.closeDropdown();
	}

	onDropdownStateChange(open: boolean) {
		this.dropdownOpen = open;
		this.cdRef.markForCheck();
		if (open) {
			this._selectedVehicles = this.formControl.value || [];
		} else {
			this.searchFormControl.setValue('');
		}
		this._currentSelectedUnion.next(this._selectedVehicles[0]?.unionUuid || undefined);
	}

	/**
	 * Do not delete, is called automatically by the form
	 */
	validate(control: AbstractControl): ValidationErrors | null {
		if (!this.formControl.validator) {
			this.formControl.setValidators(control.validator);
		}

		return null;
	}

	/**
	 * Takes the serials from the query params and sets the vehicles to the selected vehicles
	 * @example
	 * We get 123481723,1230129384 from the query params
	 * When we receive the vehicles from the backend, we need to set the vehicles that have the serials 123481723 and 1230129384
	 * to the form control
	 *
	 * @param serials
	 * @param vehicles
	 * @private
	 */
	private setVehiclesToSelectedVehiclesFromSerialStrings(
		serials: string[],
		vehicles: Vehicle[]
	): void {
		if (serials?.length && vehicles?.length) {
			const selectedVehicles = vehicles.filter((vehicle) => serials.includes(vehicle.serial));
			selectedVehicles.forEach((vehicle) => {
				this.onSelect(vehicle);
			});
			this.onSave();
		}
	}

	private scrollToTop() {
		setTimeout(() => {
			const unionHeader: HTMLElement | null | undefined =
				this.optionsList?.nativeElement.querySelector('#selected-union-header');
			if (unionHeader) {
				unionHeader.scrollIntoView({ behavior: 'smooth', block: 'start' });
			}
		});
	}

	public toggleDropdown() {
		this.dropdown?.toggleDropdown();
	}

	private sortOptions(response: Response<Vehicle>): Response<Vehicle> {
		const selectedSerials = this.selectedSerials || [];
		const selectedVehicles = response.data.filter((vehicle) =>
			selectedSerials.includes(vehicle.serial)
		);
		const unselectedVehicles = response.data.filter(
			(vehicle) => !selectedSerials.includes(vehicle.serial)
		);
		return {
			...response,
			data: [...selectedVehicles, ...unselectedVehicles],
		};
	}
}
