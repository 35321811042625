<div class="c-user-notification-item">
	<div class="c-user-notification-item__tags">
		<ui-tag
			[text]="(notification.created | date: 'dd.MM.yyyy' : 'UTC') ?? ''"
			[type]="notification.read ? 'secondaryLight' : 'dark'"
		></ui-tag>
		@if (!notification.read) {
			<ui-tag [text]="'notifications.new' | translate" type="dark"></ui-tag>
		}
	</div>
	<div>
		<ui-headline type="h4" class="c-user-notification-item__title">
			{{ notification.title }}
		</ui-headline>
	</div>
	<div
		[innerHTML]="notification.text | trusted"
		class="c-user-notification-item__notificationText"
	></div>
</div>
