import {
	ApplicationRef,
	ChangeDetectorRef,
	ComponentFactoryResolver,
	ComponentRef,
	Directive,
	ElementRef,
	EmbeddedViewRef,
	HostListener,
	Injector,
	Input,
	OnDestroy,
	Renderer2,
} from '@angular/core';
import { TooltipComponent } from '../component/tooltip/tooltip.component';
import { Position, Positions } from '@agilox/ui-common';

@Directive({
	selector: '[uiTooltip]',
})
export class TooltipDirective implements OnDestroy {
	@Input() uiTooltip: string = '';
	@Input() tooltipVisible: boolean = true;
	@Input() tooltipPosition: Position = Positions.Top;

	public componentRef: ComponentRef<any> | null = null;

	constructor(
		private elementRef: ElementRef,
		private appRef: ApplicationRef,
		private componentFactoryResolver: ComponentFactoryResolver,
		private injector: Injector,
		private renderer: Renderer2
	) {}

	@HostListener('mouseenter')
	onMouseEnter(): void {
		this.initializeTooltip();
	}

	@HostListener('mouseleave')
	onMouseLeave(): void {
		this.destroy();
	}

	@HostListener('click')
	onMouseClick(): void {
		this.destroy();
	}

	@HostListener('touchstart', ['$event'])
	onTouchStart(): void {
		this.disableTextSelection();
		this.initializeTooltip();
	}

	@HostListener('touchend', ['$event'])
	onTouchEnd(): void {
		//Necessary timeout to handle flickering effect
		setTimeout(() => {
			this.destroy();
		}, 250);
	}

	/**
	 * Creates an instance of the tooltip component and attaches it to the view
	 * @private
	 */
	private initializeTooltip() {
		if (this.componentRef === null && this.tooltipVisible && this.uiTooltip) {
			const componentFactory =
				this.componentFactoryResolver.resolveComponentFactory(TooltipComponent);
			this.componentRef = componentFactory.create(this.injector);

			this.appRef.attachView(this.componentRef.hostView);
			const [tooltipDOMElement] = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes;
			this.setTooltipComponentProperties();
			document.body.appendChild(tooltipDOMElement);

			setTimeout(() => {
				this.showTooltip();
			});
		}
	}

	private setTooltipComponentProperties() {
		if (this.componentRef !== null) {
			this.componentRef.instance.tooltip = this.uiTooltip;
			this.componentRef.instance.position = this.tooltipPosition;

			const { left, right, top, bottom } = this.elementRef.nativeElement.getBoundingClientRect();

			switch (this.tooltipPosition) {
				case Positions.Bottom: {
					this.componentRef.instance.left = Math.round((right - left) / 2 + left);
					this.componentRef.instance.top = Math.round(bottom);
					break;
				}
				case Positions.Top: {
					this.componentRef.instance.left = Math.round((right - left) / 2 + left);
					this.componentRef.instance.top = Math.round(top - 10);
					break;
				}
				case Positions.Right: {
					this.componentRef.instance.left = Math.round(right);
					this.componentRef.instance.top = Math.round(top + (bottom - top) / 2);
					break;
				}
				case Positions.Left: {
					this.componentRef.instance.left = Math.round(left);
					this.componentRef.instance.top = Math.round(top + (bottom - top) / 2);
					break;
				}
				default: {
					break;
				}
			}
		}
	}

	private showTooltip() {
		if (this.componentRef !== null) {
			this.componentRef.instance.visible = true;
			this.componentRef.injector.get(ChangeDetectorRef).markForCheck();
		}
	}

	ngOnDestroy(): void {
		this.destroy();
	}

	destroy(): void {
		if (this.componentRef !== null) {
			this.appRef.detachView(this.componentRef.hostView);
			this.componentRef.destroy();
			this.componentRef = null;
		}
	}

	/*
  Handles  cases where a prolonged touch event triggers text election of the touched element
   */
	private disableTextSelection() {
		this.renderer.setStyle(this.elementRef.nativeElement, '-webkit-user-select', 'none');
		this.renderer.setStyle(this.elementRef.nativeElement, '-moz-user-select', 'none');
		this.renderer.setStyle(this.elementRef.nativeElement, '-ms-user-select', 'none');
		this.renderer.setStyle(this.elementRef.nativeElement, 'user-select', 'none');
	}
}
