<div class="ui-select-option-list" #optionsList (scroll)="onScroll()">
	@if (optionGroups && optionGroups.length > 0) {
		<div class="ui-select-option-list__group" *ngFor="let group of optionGroups">
			<div
				class="ui-select-option-list__groupName"
				*ngIf="group.name"
				(click)="onGroupToggle(group)"
				[ngClass]="{
					'ui-select-option-list__groupName--toggleable': groupSelectable && !group.isDisabled,
				}"
				[class.is-disabled]="group.isDisabled"
			>
				{{ group.name | translate }}
			</div>
			<div *ngFor="let option of group.options">
				<ui-select-option
					[option]="option"
					[disabled]="option.isDisabled"
					[multiEnabled]="multiEnabled"
					[selected]="compareFn(option)"
					(optionSelected)="toggleOption($event)"
					[focused]="focusedOption === option"
					[attr.data-option-index]="option.index"
					[customOptionTemplate]="customOptionTemplate"
					[fullWidth]="fullWidth"
				>
				</ui-select-option>
			</div>
		</div>
	} @else {
		<div class="ui-select-option-list__notify">
			<ui-notify [text]="'general.no_data' | translate"></ui-notify>
		</div>
	}
</div>
