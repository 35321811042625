import { inject, Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, map, Observable, of } from 'rxjs';
import { AGILOX_API_ENDPOINT, App, ENVIRONMENT, Environment } from '@agilox/common';
import { UserNotificationsState } from './models/userNotificationsState';
import { UserNotification } from './models/user-notification';

@Injectable()
export class UserNotificationsService {
	private http: HttpClient = inject(HttpClient);
	@Inject(AGILOX_API_ENDPOINT) private apiEndpoint: string = inject(AGILOX_API_ENDPOINT);

	private _state: BehaviorSubject<UserNotificationsState> =
		new BehaviorSubject<UserNotificationsState>({
			notifications: [],
			hasUnreadNotifications: false,
			isOpen: false,
		});
	readonly state$: Observable<UserNotificationsState> = this._state.asObservable();

	setNews(news: UserNotification[]) {
		this._state.next({
			...this._state.value,
			notifications: news,
			hasUnreadNotifications: news.filter((n: UserNotification) => !n.read).length > 0,
		});
	}

	fetchNews(app: App): Observable<UserNotification[]> {
		return this.http
			.post<{ status: boolean; notifications: UserNotification[] }>(
				this.apiEndpoint + '/v2/user/listNotifications',
				{
					app: app,
				}
			)
			.pipe(map(this.parseNotifications));
	}

	parseNotifications(data: { status: boolean; notifications: any }): UserNotification[] {
		if (!data.notifications) {
			return [];
		}

		const decodeText = (text: string) => {
			try {
				return decodeURIComponent(window.atob(text));
			} catch (e) {
				return text;
			}
		};

		return data.notifications.map((notification: any) => {
			return {
				...notification,
				text: decodeText(notification.text),
				created: notification.created * 1000,
			};
		});
	}

	toggleNotificationsOffcanvas(): void {
		this._state.next({
			...this._state.value,
			isOpen: !this._state.value.isOpen,
		});
	}

	markAsRead() {
		const ids: number[] = this._state.value.notifications
			.filter((article: UserNotification) => !article.read)
			.map((article: UserNotification) => article.id);
		if (ids.length) {
			return this.http.post<{ status: boolean }>(this.apiEndpoint + '/v2/user/notificationsRead', {
				id: ids,
			});
		} else {
			return of({ status: true });
		}
	}

	setStateToRead(): void {
		this._state.next({
			...this._state.value,
			notifications: this._state.value.notifications.map((article: UserNotification) => {
				article.read = true;
				return article;
			}),
			hasUnreadNotifications: false,
		});
	}
}
