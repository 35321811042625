<div
	#offcanvasElement
	class="ui-offcanvas"
	[class.is-open]="open"
	[@slideInOut]="open ? 'open' : 'closed'"
>
	<div class="ui-offcanvas__header">
		<ui-headline type="h3">{{ header | translate }}</ui-headline>
		<ui-icon
			*ngIf="showCloseIcon"
			icon="x_cross"
			size="m"
			[action]="true"
			(iconClicked)="close()"
			data-cy="ui-offcanvas-close-icon"
		></ui-icon>
	</div>

	<div class="ui-offcanvas__container">
		<ng-content></ng-content>
	</div>
	<div class="ui-offcanvas__buttons">
		<ng-content select="[offcanvas-buttons]"></ng-content>
	</div>
</div>
