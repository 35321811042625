import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserNotificationsComponent } from './user-notifications.component';
import { UserNotificationsService } from './user-notifications.service';
import { PipeTrustedModule } from '@agilox/pipe/trusted';
import { SpinnerComponent } from '../spinner/spinner.component';
import { UserNotificationsButtonComponent } from './user-notifications-button/user-notifications-button.component';
import { TranslateModule } from '@ngx-translate/core';
import { UserNotificationItemComponent } from './user-notification/user-notification-item.component';
import { HeadlineComponent } from '../headline/headline.component';
import { ButtonComponent } from '../button/button.component';
import { DividerComponent } from '../divider/divider.component';
import { TagComponent } from '../tag/tag.component';
import { OffcanvasComponent } from '../offcanvas/offcanvas.component';

@NgModule({
	declarations: [
		UserNotificationsComponent,
		UserNotificationsButtonComponent,
		UserNotificationItemComponent,
	],
	imports: [
		CommonModule,
		OffcanvasComponent,
		PipeTrustedModule,
		SpinnerComponent,
		ButtonComponent,
		TranslateModule,
		HeadlineComponent,
		ButtonComponent,
		DividerComponent,
		TagComponent,
	],
	exports: [
		UserNotificationsComponent,
		UserNotificationsButtonComponent,
		UserNotificationItemComponent,
	],
	providers: [UserNotificationsService],
})
export class UserNotificationsModule {}
