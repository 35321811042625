import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NotifyIcons, Sizes, State, States } from '@agilox/ui-common';
import { IconModule } from '../icon/icon.module';

@Component({
	selector: 'ui-notify',
	standalone: true,
	imports: [IconModule],
	templateUrl: './notify.component.html',
	styleUrls: ['./notify.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotifyComponent {
	@Input() type: State = States.Info;
	@Input() text: string = '';
	public notifyIcons = NotifyIcons;
	public sizes = Sizes;
}
