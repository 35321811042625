<div class="ui-breadcrumb">
	@for (
		breadcrumb of breadcrumbs | breadcrumbEllipsis: _maxBreadcrumbs : expanded;
		track breadcrumb;
		let last = $last, first = $first
	) {
		<div class="ui-breadcrumb__breadcrumb" [class.is-active]="last">
			<div class="ui-breadcrumb__link" (click)="doRoute(breadcrumb)">
				@if (first) {
					<ui-icon [icon]="icon" size="xs" class="ui-breadcrumb__icon"></ui-icon>
				}
				@if (breadcrumb.label) {
					<span class="ui-breadcrumb__label">{{ breadcrumb.label | translate }}</span>
				} @else {
					<span class="ui-breadcrumb__ellipsis" (click)="expanded = true">{{ breadcrumb }}</span>
				}
			</div>

			@if (!last) {
				<ui-icon icon="x_arrow_right" size="xs" class="ui-breadcrumb__divider"></ui-icon>
			}
		</div>
	}
</div>
